import { useEffect, useState } from 'react';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  PaperAirplaneIcon,
  CheckCircleIcon,
  ArrowPathIcon,
  FaceSmileIcon,
} from '@heroicons/react/20/solid';
import { questions } from './questions';
import { country } from './country';
import axios from 'axios';

const TYPE = {
  SELECTION: 'SELECTION',
  MULTI_SELECTION: 'MULTI_SELECTION',
  TEXT: 'TEXT',
  DROPDOWN: 'DROPDOWN',
};

const RejectCard = () => {
  return (
    <div className='bg-[#626c39] rounded-xl p-4 text-center'>
      <div className='text-[#e5eccc] border-[#e5eccc] flex justify-center items-center mb-2'>
        <FaceSmileIcon className='size-14' />
      </div>
      <div className='text-white font-bold mb-4'>
        Thank you for your interest in my program.
      </div>
    </div>
  );
};

const SuccessCard = () => {
  return (
    <div className='bg-[#626c39] rounded-xl p-4 text-center'>
      <div className='text-[#e5eccc] border-[#e5eccc] flex justify-center items-center mb-2'>
        <CheckCircleIcon className='size-14' />
      </div>
      <div className='text-white font-bold mb-4'>
        <div className='font-extrabold text-xl'>Thank you!</div>
        <p>I will contact you via WhatsApp or email as soon as possible.</p>
      </div>
    </div>
  );
};

const PhoneCard = ({
  page,
  question,
  onChange,
  dataKey,
  codeValue,
  value,
  handleNextPage,
  handlePrevPage,
  loading,
}) => {
  return (
    <div className='bg-[#626c39] rounded-xl p-4'>
      <div className='text-white font-bold mb-4'>{question}</div>
      <div className='font-bold flex items-center gap-2'>
        <select
          className='w-1/4 rounded-xl py-3 text-[#626c39] px-2 shrink-0'
          value={codeValue}
          onChange={onChange('countryCode')}
        >
          {country.map((option) => (
            <option
              key={option.name}
              value={`+${option.phoneCode}`}
            >
              {option.emoji} {option.name} +{option.phoneCode}
            </option>
          ))}
        </select>
        <div className='rounded-xl w-full bg-white px-2 flex items-center'>
          <div className='opacity-40 shrink-0'>{codeValue}</div>
          <input
            className='rounded-xl w-full px-2 py-[10.5px] outline-none'
            placeholder='Your phone number'
            value={value}
            onChange={onChange(dataKey)}
          />
        </div>
      </div>
      <div className='flex w-full justify-between gap-2 items-center mt-8'>
        <button
          className={`rounded-xl bg-white w-1/2 py-2 flex justify-center items-center ${
            page === 0 && 'opacity-40'
          }`}
          onClick={handlePrevPage}
          disabled={loading}
        >
          <ChevronDoubleLeftIcon className='size-5 mr-2' /> Back
        </button>
        <button
          className={`rounded-xl bg-white w-1/2 py-2 flex justify-center items-center ${
            (!value || !codeValue) && 'opacity-40'
          }`}
          onClick={handleNextPage}
        >
          Next <ChevronDoubleRightIcon className='size-5 ml-2' />
        </button>
      </div>
    </div>
  );
};

const Card = ({
  isLastPage,
  page,
  question,
  type,
  options = [],
  placeholder = '',
  onClick,
  onChange,
  dataKey,
  value,
  handleNextPage,
  handlePrevPage,
  loading,
  handleSubmit,
}) => {
  return (
    <div className='bg-[#626c39] rounded-xl p-4'>
      <div className='text-white font-bold mb-4'>{question}</div>
      <div className='space-y-2 font-bold'>
        {type === TYPE.SELECTION &&
          options.map((option) => (
            <button
              key={option}
              onClick={onClick(dataKey, option)}
              className={`rounded-xl w-full py-3 text-[#626c39] ${
                value === option
                  ? 'bg-[#626c39] border border-[#e5eccc] text-[#e5eccc] contrast-200'
                  : 'bg-white'
              }`}
            >
              {option}
            </button>
          ))}
        {type === TYPE.MULTI_SELECTION &&
          options.map((option) => (
            <button
              key={option}
              onClick={onClick(dataKey, option)}
              className={`rounded-xl w-full py-3 text-[#626c39] ${
                value.includes(option)
                  ? 'bg-[#626c39] border border-[#e5eccc] text-[#e5eccc] contrast-200'
                  : 'bg-white'
              }`}
            >
              {option}
            </button>
          ))}
        {type === TYPE.DROPDOWN && (
          <select
            className='w-full rounded-xl py-3 text-[#626c39] px-2'
            value={value}
            onChange={onChange(dataKey)}
          >
            <option
              value=''
              disabled
            >
              Select an option
            </option>
            {options.map((option) => (
              <option
                key={option}
                value={option}
              >
                {option}
              </option>
            ))}
          </select>
        )}
        {type === TYPE.TEXT && (
          <input
            className='rounded-xl w-full py-3 bg-white px-2'
            placeholder={placeholder}
            value={value}
            onChange={onChange(dataKey)}
          />
        )}
      </div>
      <div className='flex w-full justify-between gap-2 items-center mt-8'>
        <button
          className={`rounded-xl bg-white w-1/2 py-2 flex justify-center items-center ${
            page === 0 && 'opacity-40'
          }`}
          onClick={handlePrevPage}
          disabled={loading}
        >
          <ChevronDoubleLeftIcon className='size-5 mr-2' /> Back
        </button>
        {isLastPage ? (
          <button
            className={`rounded-xl w-1/2 py-2 flex justify-center items-center bg-[#626c39] border border-[#e5eccc] text-[#e5eccc] contrast-200 ${
              !value && 'opacity-40'
            }`}
            onClick={value ? handleSubmit : null}
            disabled={loading}
          >
            {loading ? (
              <ArrowPathIcon className='size-5 animate-spin' />
            ) : (
              <>
                Submit <PaperAirplaneIcon className='size-5 ml-2' />
              </>
            )}
          </button>
        ) : (
          <button
            className={`rounded-xl bg-white w-1/2 py-2 flex justify-center items-center ${
              !value && 'opacity-40'
            }`}
            onClick={handleNextPage}
          >
            Next <ChevronDoubleRightIcon className='size-5 ml-2' />
          </button>
        )}
      </div>
    </div>
  );
};

const Form = () => {
  const [data, setData] = useState({
    purpose: '',
    gender: '',
    age: '',
    job: '',
    countryCode: '',
    preferPlace: '',
    equipments: [],
    willingToPay: '',
    name: '',
    phone: '',
    email: '',
  });
  const [page, setPage] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (page < questions.length) {
      setCurrentQuestion(questions.find((q) => q.page === page));
    }
  }, [page]);

  const handleNextPage = () => {
    if (
      currentQuestion.key === 'equipments' &&
      data[currentQuestion.key].length > 0
    ) {
      setPage((prev) => (prev < questions.length - 1 ? prev + 1 : prev));
    }
    if (currentQuestion.key === 'phone') {
      if (data[currentQuestion.key] && data.countryCode) {
        setPage((prev) => (prev < questions.length - 1 ? prev + 1 : prev));
      }

      return;
    }
    if (currentQuestion.key !== 'equipments' && data[currentQuestion.key]) {
      setPage((prev) => (prev < questions.length - 1 ? prev + 1 : prev));
    }
  };

  const handlePrevPage = () => {
    setPage((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleSelectData = (key, value) => () => {
    if (key === 'equipments') {
      const arr = data[key].slice();
      if (arr.includes(value)) {
        const index = arr.indexOf(value);
        arr.splice(index, 1);
      } else {
        arr.push(value);
      }

      value = arr;
    }

    setData((prev) => ({
      ...prev,
      [key]: value,
    }));

    setTimeout(() => {
      if (key !== 'equipments') {
        setPage((prev) => (prev < questions.length - 1 ? prev + 1 : prev));
      }
    }, 250);

    if (key === 'willingToPay' && value === 'No') {
      setTimeout(() => {
        reset();
      }, 3000);
    }
  };

  const handleChangeData = (key) => (e) => {
    let value = e.target.value;

    if (key === 'age') {
      value = Number(value);
    }

    if (key === 'countryCode') {
      return setData((prev) => ({
        ...prev,
        countryCode: value,
      }));
    }

    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailRegex.test(data.email)) {
        return alert('Invalid email format');
      }

      setPage((prev) => prev + 1);
      setLoading(true);

      const body = Object.assign({}, data, {
        phone: `${data.countryCode}${data.phone.replace(/[^0-9]/g, '')}`,
      });

      const res = await axios.post('https://api.dx2.link/submit/application', {
        subject: `<${data.name}> New Online Coaching Applicant`,
        data: body,
      });

      if (res?.status === 200) {
        setSubmitted(true);
      } else {
        alert('Something went wrong.\nPlease DM me if it is not working.');
      }
    } catch (err) {
      console.log(err);
      alert('Something went wrong.\nPlease DM me if it is not working.');
    } finally {
      setLoading(false);
    }
  };

  const reset = () => {
    setPage(0);
    setData({
      purpose: '',
      gender: '',
      age: '',
      job: '',
      countryCode: '',
      preferPlace: '',
      equipments: [],
      willingToPay: '',
      name: '',
      phone: '',
      email: '',
    });
  };

  return (
    <div className='space-y-4'>
      <div className='w-full bg-white rounded-full h-2'>
        <div
          className='bg-[#626c39] h-2 rounded-full transition-width duration-500'
          style={{ width: `${(page / questions.length) * 100}%` }}
        ></div>
      </div>
      {submitted ? (
        <SuccessCard />
      ) : data.willingToPay === 'No' ? (
        <RejectCard />
      ) : currentQuestion.key === 'phone' ? (
        <PhoneCard
          page={page}
          question={currentQuestion.question}
          onChange={handleChangeData}
          dataKey={currentQuestion.key}
          codeValue={data.countryCode}
          value={data[currentQuestion.key]}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          loading={loading}
        />
      ) : (
        <Card
          page={page}
          isLastPage={page >= questions.length - 1}
          question={currentQuestion.question}
          type={currentQuestion.type}
          options={currentQuestion.options}
          placeholder={currentQuestion.placeholder}
          onClick={handleSelectData}
          onChange={handleChangeData}
          dataKey={currentQuestion.key}
          value={data[currentQuestion.key]}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          loading={loading}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default Form;
