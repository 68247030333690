import HeroSection from './sections/hero';
import AboutSection from './sections/about';
import AboutNumberSection from './sections/aboutNumber';
import AboutPTSection from './sections/aboutPT';
import AboutAppSection from './sections/aboutApp';
import CalorieCalculationSection from './sections/calorieCalculation';
import ReviewSection from './sections/review';
import ApplicationSection from './sections/application';
import ProgramSection from './sections/program';
import FAQSection from './sections/faq';
import Footer from './sections/footer';
import Nav from './menu';

const App = () => {
  return (
    <div className='scroll-smooth max-w-3xl mx-auto'>
      <Nav />
      <HeroSection />
      <AboutSection />
      <AboutNumberSection />
      <AboutPTSection />
      <AboutAppSection />
      <CalorieCalculationSection />
      <ReviewSection />
      <ApplicationSection />
      <ProgramSection />
      <FAQSection />
      <Footer />
      {/* <div className="fixed bg-white w-16 h-16 rounded-full bottom-2 right-2 flex justify-center items-center text-center text-xs bg-[url('https://public-file.wenow.me/davidpark/programs/9days/9days_program_logo.jpg')] bg-contain text-white font-bold"></div> */}
    </div>
  );
};

export default App;
