export const questions = [
  {
    page: 0,
    key: 'purpose',
    type: 'SELECTION',
    question: 'What is your fitness goal?',
    options: [
      'Lose Weight',
      'Build Muscle',
      'Build a Lifelong Healthy Habit',
      'All of the Above',
    ],
  },
  {
    page: 1,
    key: 'gender',
    type: 'SELECTION',
    question: 'How do you identify?',
    options: ['Female', 'Male', 'Other', 'Prefer not to answer'],
  },
  {
    page: 2,
    key: 'age',
    question: 'How old are you? (Starting from age 18 and up)',
    type: 'DROPDOWN',
    options: [
      18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
      36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
      54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,
      72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89,
      90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
    ],
  },
  {
    page: 3,
    key: 'job',
    type: 'SELECTION',
    question:
      'To help me guide you effectively and understand your lifestyle better, could you please tell me what you do for a living?',
    options: ['Student', 'Self employed', 'Part-time', 'Full-time'],
  },
  {
    page: 4,
    key: 'preferPlace',
    type: 'SELECTION',
    question: 'Where do you prefer to workout?',
    options: ['Home', 'Gym', 'Both'],
  },
  {
    page: 5,
    key: 'equipments',
    type: 'MULTI_SELECTION',
    question:
      'Which equipment do you have at home? This will help me create the best possible customized workout program tailored to your available equipment. (Multi-selection)',
    options: [
      'Dumbbells',
      'Pull-up bar',
      'Dips bar',
      'Resistance Band',
      'Bench',
      'Kettlebell',
      'No Equipment',
    ],
  },
  {
    page: 6,
    key: 'willingToPay',
    type: 'SELECTION',
    question:
      'Are you ready to invest in your fitness journey with 1:1 coaching at just $6 per day for 9 weeks? This paid program includes personalized workouts, nutrition guidance, weekly check-ins, and direct support. Let’s get started!',
    options: ['Yes', 'No'],
  },
  {
    page: 7,
    key: 'name',
    type: 'TEXT',
    question: 'First Name and Last Name',
    options: [],
    placeholder: 'e.g. David Park',
  },
  {
    page: 8,
    key: 'phone',
    type: 'TEXT',
    question: 'What is your "WhatsApp"(phone) number?',
    options: [],
  },
  {
    page: 9,
    key: 'email',
    type: 'TEXT',
    question: 'What is your email address?',
    options: [],
    placeholder: 'e.g. david.park@gmail.com',
  },
];
